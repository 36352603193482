import "./index.css";
import Images from "../images";

const RESULT = {
  female: {
    ISTJ: {
      title: "帥氣俐落線條的日系短髮",
      tags: "#勤奮、追求安全和穩定、正直務實、使命必達 #日系細緻剪裁襯托妳的冷靜穩重",
      info: "你是個喜歡徹頭徹尾遵從計畫的人，每天規律的生活作息最讓你感到安心！注重秩序、對自我高標準，是你和世界共處的方式。行動力很強的你，總是非常獨立地先一步走在別人前面！雖然你不善於向身旁的人表露情感，但對任何人事物都有責任心，也總是用行動展現你的誠信與真心！嚴謹又勤奮向上的你，最適合線條俐落乾淨的日系短髮，搭配幹練的黑髮色，讓你整體氣質就如同你的個性一樣穩重，令人感到信任又放心。",
      magic:
        "短髮最怕顯得臉大頭大了！這款日系短髮，根據你的頭型和臉型設計，俐落的剪裁使得你的後頭部飽滿不扁塌，勾耳後的小心機設計可以讓五官輪廓更加鮮明。",
    },
    ISFJ: {
      title: "低層次及肩內彎髮",
      tags: "#情感細膩、享受為他人奉獻的善良女孩 #低層次內彎髮最能襯托你的柔軟氣質",
      info: "總是溫暖、細心、樂於照顧朋友的你，就像一個能夠融化任何人心房的小太陽！也許你總覺得自己在團體裡只是個不起眼的小螺絲釘，但其實身邊的人內心都這麼想：『要是沒有了你，我們該怎麼辦！』享受與他人分享、奉獻之餘，也別忘了要關照自己的內心，溫柔的及肩髮，搭配低層次的內彎設計，最能夠凸顯你的平易近人的細膩氣質，搭配瀏海的修飾，更能將你浪漫可愛的內在形象表露無遺！這個髮型與你天生自帶的柔軟氣息，都給人一種如沐春風的舒適氛圍！",
      magic:
        "低層次的內彎比起可愛，更多了一點溫柔知性的氣質，最適合卡在留長過渡期的妳！把髮長設定在肩上的彎度，不只能修飾脖頸處的線條，還能延伸肩頸的比例，整理起來也非常簡單，不用花時間手繞，順順地吹乾，捲度就能自然成型！",
    },
    INFJ: {
      title: "自然又有神秘感的線條及肩髮",
      tags: "#細膩、敏感愛幻想、具洞察力、溫柔對待世界 #自然微捲的及肩髮最有氣質",
      info: "你內心充滿善良及溫暖、細膩敏感、包容性極強，不喜歡給任何人造成麻煩及負擔，害怕被拒絕，卻也不善於拒絕別人。你堅定正直、有原則，只不過可能會過度理想化的活在自己的世界，甚至容易產生自卑感，陷入自我懷疑的輪迴，但請你記得在給全世界溫柔之前，更要先懂得好好愛自己。喜歡低調不張揚的你，比起全頭髮色改變，更適合低調的挑染線條；比起大捲度的波紋，更適合自然微捲的樣子，符合你曖曖內含光的神秘氣質。",
      magic:
        "及肩髮長加上低層次的剪裁設計，讓髮型有微微的凌亂感，看起來更加慵懶。髮絲之間點綴上若隱若現的線條挑染，微風吹過閃現動態線條感，低調中襯托出你的神秘氣質！",
    },
    INTJ: {
      title: "簡單有氣質的韓系緞帶燙",
      tags: "#效率、計畫通、思維嚴謹、追求持續成長 #蓬鬆捲度、輕盈紋理，讓你多點溫暖、少點距離感",
      info: "在你的領域，你的專業知識無人能敵，也非常善於制定計劃，如果事情沒有按照你制定的一磚一瓦走的話，你可能就會崩潰。你喜歡接觸新知，經常在獨處時思考這個世界各種學問，並嘗試讓自己不斷跳出舒適圈，『精益求精』是你在生活上最重要的信念！你是一個超級完美主義者，完美到連對別人的要求都跟對自己一樣苛刻，所以在外人眼中經常看起來冷漠無情也難以親近。魔髮師建議你嘗試平易近人的韓系輕盈捲度，為你有距離感的外表增加一些溫暖氛圍，再搭配上溫柔可人的棕色系染髮，柔化你的整體形象！",
      magic:
        "韓式氣墊燙加上小顏瀏海的設計，不只顯臉小，還能加深五官立體度，蓬鬆的捲度，讓一頭長髮看起來更輕盈、有精神！",
    },
    ISTP: {
      title: "齊長無層次的挑染短髮",
      tags: "#保守務實、內心充滿好奇、行動力強 #齊長短髮代表你的務實嚴謹",
      info: "你喜歡解決問題，遇到困難絕不會逃避，是個非常有行動力的人！低調謙虛的你，在他人眼中可能顯得冷淡寡言，時常透露出生人勿近的感覺，比起言語上的拐彎抹角，你更喜歡直接用行動表示，雖然不太懂浪漫，卻總是默默的關心他人。魔髮師建議你可以嘗試線條挑染搭配上齊長的頭髮，在你嚴謹的個性上，加上了一點想跟現實世界接軌的躁動的心，嘗試改變，也會讓你更加的愛自己喔！",
      magic:
        "齊長的層次在髮尾及瀏海，在平常造型上是有非常大的變化空間的，可以嘗試外翹或內捲，甚至瀏海的彎度也能夠自行調整。不過，想要讓髮絲齊長看起來更加俐落，還是建議有自然捲的人要先經過縮毛矯正讓髮絲不毛躁，看起來更順喔！",
    },
    ISFP: {
      title: "時髦的層次感鎖骨髮",
      tags: "#溫柔文靜、談吐文雅、自由灑脫、滿滿藝術魂 #層次羽毛感最符合你的自由靈魂",
      info: "看似自由灑脫不喜歡受拘束的你，實則性格細膩又敏銳。不認識你的人總是會被你的冰山外表給嚇跑不敢靠近你，殊不知呀，只要你的話匣子一打開，從天文地理聊到星座運勢你都停不下來....在一群朋友當中，你屬於默默的觀察派，重視整體關係的和諧，即便看破也不會說破，破壞氣氛最不符合你溫柔的氣質了！細膩敏感的你，最適合一顆輕盈的層次鎖骨髮，溫文儒雅中又帶點靈動的活潑，風輕輕吹過髮絲間營造出來的羽毛感，最能展現你自由不羈的意志。",
      magic:
        "透過髮尾層層堆疊的羽毛層次，讓你的頭髮看起來會更加輕盈活潑。對於有自然捲困擾的人來說，只要做過縮毛矯正，剪有層次的髮型也可以再也不用怕毛躁難整理了！",
    },
    INFP: {
      title: "清新甜美的低層次自然波紋",
      tags: "#反差、有理想、多愁善感、堅毅又冷漠 #鬆軟的捲度最輕盈自在",
      info: "你自身就是一個矛盾體，外在冷靜害羞，內心其實充滿熱情，願意關懷他人，但也十分注重自己內心的和諧，經常需要透過獨處來消化情緒。平時主張和諧，非常有耐心和包容力，但當自己的價值觀受到質疑的時候，你會堅持自己的原則，非常固執！你喜愛探索、熱衷學習不同文化與新事物，但你對每件事情都過度講求完美的心態，會讓你整個人看起來太緊繃！外冷內熱的你最適合鬆鬆軟軟的韓系捲度，搭配上減齡瀏海的修飾，能降低距離感，展現出讓人意想不到的反差萌！",
      magic:
        "低層次自然波紋的設計營造出甜美溫暖的氛圍，搭配上充滿柔和空氣感的瀏海，修飾臉型，有更無辜的感覺。簡單的捲度建議搭配上偏霧感的髮色，會讓整體氣質更清新自然喔！",
    },
    INTP: {
      title: "神秘俏麗的日系低層次短髮",
      tags: "#獨立、邏輯強、善於解決問題、喜歡沉思活在自己世界 #低層次剪裁更顯你的聰明低調",
      info: "你內向理性，喜歡待在自己的世界裡，不懂你的人可能覺得你難相處，總是看起來冷冰冰的。渴望獨立、喜愛思考，讓你成為別人眼中特別的存在。你對於感興趣的議題總是洋溢著熱情，也善於提出獨到的見解。你很注重理性的邏輯思考和溝通方式，譬如說你在吵架的時候，比起感性的爭一口輸贏，你更注重真實的對錯，並會認真的以邏輯去分析現況。這樣的你最建議你換上一顆造型感十足的日系短髮，有層次感的剪裁設計搭配個性十足的眉上瀏海，更能凸顯你追求獨立、嚮往神秘的個人特質！",
      magic:
        "日系低層次短髮的剪裁加上精細線條的設計感，讓臉部輪廓更加鮮明，搭配上簡易的造型製造更多線條濕潤感的話，也會讓整體氛圍更充滿神秘俐落感。",
    },
    ESTP: {
      title: "充滿自信的明亮俐落中長髮",
      tags: "#活潑有魅力、善於社交、享受生活 #淺色系髮色讓你走在時尚最前端",
      info: "想要在人群中快速找到你，跟著大家的目光看去準沒錯！你不只活躍在各種社交場合，也總是能輕易成為人群中的焦點，在與別人相處時，你言行舉止中流露出的自信與愜意，就是你最大的魅力之一！充滿創意、積極付諸行動、享受活在當下、擁有說做就做的勇氣，都是你被他人羨慕的特點。總是正能量爆棚、喜歡挑戰嘗鮮的你，魔髮師建議嘗試更多淺色系的髮色，讓你整體氛圍更加明亮、活潑，搶先走在流行的最前端。搭配上俐落的層次剪裁設計，讓你風格大膽卻不落入俗套，反而散發更加幹練、使人信服的魅力與氣場。",
      magic:
        "如果你有常覺得髮尾很厚重的困擾的話，可以嘗試在髮尾加入若有似無的層次剪裁，讓視覺上清爽許多，或是透過淺髮色提亮你的五官輪廓，增添整體活力氛圍。",
    },
    ESFP: {
      title: "熱情可愛的韓系泰迪捲",
      tags: "#真誠熱情、活潑好動、人群中最閃亮的一顆星 #小波紋最顯你的熱情洋溢",
      info: "只要有舞台、有燈光的地方，絕對少不了你的身影。你有強大的表演慾，擅長向他人展示自己最亮眼、最有自信的一面，不害怕舞台的你，總是非常勇敢地踏出舒適圈、擁抱新的挑戰。你非常樂天，很多事都會依靠直覺來解決，想到什麼就會直接付諸行動，旁人看來也許會覺得你過於直接魯莽，但其實你非常確定你的目標，而且勇往直前！滿腹熱血的你，非常適合波紋一致的小捲度加上可愛獨特的狗毛瀏海設計，這麼時髦的髮型只有你能駕馭，盡情展現熱情活潑的魅力！",
      magic:
        "狗毛短瀏海的設計，讓你的五官輪廓顯得更加立體好看，搭配上小捲度的韓系一致波紋，完美加強頭髮的份量感，但又不會顯得過於厚重。",
    },
    ENFP: {
      title: "豪放熱情的歐美立體捲",
      tags: "#友善、有活力、夢想實踐家、好奇心滿滿 #立體小捲度最襯你的精緻五官",
      info: "對你而言，天下沒有不可能的事，你相信團結的力量可以改變非常多的事情。活潑外向是你最常聽到的形容詞，你充滿好奇心、喜歡探索新鮮事物，因此難以忍受總是在做重複的事。你很重視與人之間的情感交流，待人真誠善良，同時內心也很希望能夠得到相等的認同和愛，這樣的你偶爾會受傷、變得脆弱。魔髮師建議你，在追求他人的愛和認同時，絕對要先愛自己，再去付出。喜歡嚐鮮、不落俗套的你，最適合小捲度的歐美紋理，不僅顯得五官立體，一踏出門，所有的目光焦點都會在你身上！",
      magic:
        "藉由捲度較小的紋理，可以讓視線集中在五官呈現上，臉看起來也會比較小喔！在平時的整理上，如果喜歡比較慵懶隨性的風格，可以用手輕輕撥順吹乾就好，若想要捲度更加立體，搭配烘罩和慕斯烘乾，會更有歐美風情喔！",
    },
    ENTP: {
      title: "自由靈魂的緞面光感燙",
      tags: "#機敏、直接、絕不被常規束縛、有邏輯善於分析 #柔美線條讓你節奏慢下來",
      info: "你生性聰明且熱愛自由、喜歡挑戰傳統常規，透過不斷的提問來探索生命並成長。你天性樂觀，即使遇到再大的挫折，都能勇敢解決。在他人眼裡，你雖然是非常容易親近的對象，但在相處過程中，你有時候會只顧著表達自己的看法，而忽視了他人的意見。魔髮師建議你換上一顆線條柔美的髮型來磨和個性上的稜角，中分瀏海設計也能讓你整體氛圍上變得更有氣質和成熟，藉由造型的轉換來讓你整個人慢下來，比起一頭熱的辯論自己的想法，偶爾也試著傾聽別人，說不定會有更多意想不到的收穫呢！",
      magic:
        "看似簡易的線條設計，其實藏滿了讓你整體氛圍變得更加柔美的小心機，特別是中分瀏海，完美修飾了臉部的輪廓，更顯臉小！此款髮型若想要髮絲呈現更加柔順，建議自然捲的人要搭配縮毛矯正讓髮質呈現更加有光澤！",
    },
    ESTJ: {
      title: "霸氣外露的日系微捲短髮",
      tags: "#外向、掌握權力、重視秩序、誠實果斷、堅守原則 #中分短髮最顯你的霸氣",
      info: "敢愛敢恨、做事相當果斷的你，天生領導者的霸氣，有時候可是讓人有覺得有點距離感而無法靠近呢！脾氣雖然有點點小火爆，但凡事注重原則跟秩序的你，都還是對事不對人的呢！尤其你特別注重跟別人約定好的日子，也會為那些重大日子付出非常多的心力，讓他人有備受重視的感覺，是你最溫柔的照顧。平時就氣場極強的你，魔髮師建議可以換上一顆有個性的日系短髮，符合你能幹俐落的氣質，加上微微的捲度，讓你在霸氣中還帶有點可愛的小反差。",
      magic:
        "中分短髮中性又帶點霸氣，微捲髮的設計讓你平常出門也簡單好整理，如果想要更加又線條感的造型，這款設計搭配上濕潤的造型產品，營造出線條感外，也更充滿個性的氛圍。",
    },
    ESFJ: {
      title: "充滿仙氣的浪漫大波浪",
      tags: "#全身散發滿到要溢出來的熱情正能量 #鬆軟輕盈的波浪捲紋，展現妳的自信氣場",
      info: "妳活潑熱情外向、喜歡交朋友，身上更是自帶滿滿正能量來感染著身旁的人，交友圈超廣的妳，走到哪裡都能受到大家的歡迎。雖然妳的一舉一動都能影響著他人，但卻從不曾令人感到不舒服、有壓迫感，對大家來說，妳是一位全身散發著魅力的天生領導者！溫暖熱心、來者不拒的妳，最適合用浪漫鬆軟又輕盈的捲度紋理來襯托妳熱情又強大的氣場，搭配暖紅色系髮色，更是能增添迷人的仙女氣質！",
      magic:
        "沒有精神的長直髮，就靠大波浪捲度來拯救吧！鬆軟、輕盈的一致捲紋，不只能柔化妳的強大氣場、增添仙氣，更最能襯托出溫暖自信的女神氣質，搭配延伸到臉際的八字瀏海修飾，更能顯臉小、增加造型線條感！",
    },
    ENFJ: {
      title: "個性俏皮的外翹短髮",
      tags: "#有主見、充滿自信、熱心、偏執狂 #外翹短髮最顯你的可愛",
      info: "你的一舉一動都充滿著自信讓人想要跟隨你，你期待帶給人們正向的愛和希望，並用你的感染力來改變社會和世界！你善於換位思考，設身處地感知他人的情緒，並且熱心地給予幫助。喜愛擁抱人群的你，最適合換上一顆日系風格強烈、充滿個性的髮型，透過小巧思的眉上短瀏海設計及俏皮的髮尾外翹，不僅能夠散發你獨特的魅力，同時也可以為你營造更加強大的氣場，幫助你更有說服力及影響力，讓人只要看一眼就深深被你吸引！",
      magic:
        "藉由髮尾的外翹彎度來修飾下巴偏尖或偏長的部分，能使得臉的下半部顯得更加圓滑。眉上齊劉海加上微彎的弧度，同時也能夠修飾較高的額頭，視覺上讓臉變得更小，也更有個性！",
    },
    ENTJ: {
      title: "低調又霸氣的中層次長髮",
      tags: "#幹練、有活力、天生的領導者、見多識廣充滿遠見 #中層次剪裁凸顯你的獨特幹練",
      info: "行動力高、做事有條理的你，總是充滿活力的迎向挑戰、順利完成各種高難度任務！你能專注處理自己份內的事，也擅長帶領大家一起完成任務，你身上總是自然地散發一股堅定的精神與魅力，『認真的女人最美麗』，絕對就是在形容你投入於專業領域時那股渾然天成的氣質！魔髮師建議，身為強者的你，可以嘗試剪裁層次分明的長髮造型，稍微地展露你的霸氣，但卻不過度張揚，同時搭配裙擺區塊染，讓髮色低調但不無聊，不僅有獨特俐落的氣質，也不會讓人因為你的氣場太過強大不敢靠近你！",
      magic:
        "髮尾錯落有致的層次剪裁設計，為整頭髮絲加點輕盈、少點厚重，搭配上區塊挑染的巧思，讓整體氛圍的層次感層層疊加。",
    },
  },
  male: {
    ISTJ: {
      title: "簡單穩重的韓系微捲髮",
      tags: "#勤奮、追求安全和穩定、正直務實、使命必達 #微捲線條展現沈穩氣質",
      info: "你是個喜歡徹頭徹尾遵從計畫的人，每天規律的生活作息最讓你感到安心！注重秩序、對自我高標準，是你和世界共處的方式。行動力很強的你，總是非常獨立地先一步走在別人前面！雖然你不善於向身旁的人表露情感，但對任何人事物都有責任心，也總是用行動展現你的誠信與真心！嚴謹又勤奮向上的你，最適合簡單的韓系微捲線條，搭配中分瀏海，讓你整體氣質就如同你的個性一樣穩重，令人感到信任又放心。",
      magic:
        "韓系微捲的線條，營造出簡單舒適的穩重氛圍，搭配上中分瀏海的設計，特別顯臉小，凸顯五官的立體。建議想嘗試這款髮型的人可以再搭配上壓貼燙，能夠明顯的讓頭型有縮小的效果喔！",
    },
    ISFJ: {
      title: "簡單穩重的韓系微捲髮",
      tags: "#情感細膩、享受為他人奉獻的善良男孩 #微捲線條展現沈穩氣質",
      info: "總是溫暖、細心、樂於照顧朋友的你，就像一個能夠融化任何人心房的小太陽！也許你總覺得自己在團體裡只是個不起眼的小螺絲釘，但其實身邊的人內心都這麼想：『要是沒有了你，我們該怎麼辦！』享受與他人分享、奉獻之餘，也別忘了要關照自己的內心。簡單的韓系微捲線條，搭配低層次的紋理設計，最能夠凸顯你天生自帶的柔軟氣息，給人一種如沐春風的舒適氛圍！",
      magic:
        "韓系微捲的線條，營造出簡單舒適的穩重氛圍，搭配上中分瀏海的設計，特別顯臉小，凸顯五官的立體。建議想嘗試這款髮型的人可以再搭配上壓貼燙，能夠明顯的讓頭型有縮小的效果喔！",
    },
    INFJ: {
      title: "日系層層堆疊的慵懶捲髮",
      tags: "#細膩、敏感愛幻想、具洞察力、溫柔對待世界 #低層次捲髮增添溫暖氣質",
      info: "你內心充滿善良及溫暖、細膩敏感、包容性極強，不喜歡給任何人造成麻煩及負擔，害怕被拒絕，卻也不善於拒絕別人。你堅定正直、有原則，只不過可能會過度理想化的活在自己的世界，甚至容易產生自卑感，陷入自我懷疑的輪迴，但請你記得在給全世界溫柔之前，更要先懂得好好愛自己。喜歡低調不張揚的你，比起風格強烈的紋理設計，更適合層層堆疊的自然捲度，符合你曖曖內含光的神秘氣質。",
      magic:
        "日系的低層次紋理，營造出像剛睡醒般的慵懶氣質，層層堆疊的捲度，最能展現日系男子的柔和氣場。此款捲髮若搭配慕斯系列的濕式造型品塗抹，會更有男人味喔！",
    },
    INTJ: {
      title: "日系層層堆疊的慵懶捲髮",
      tags: "#效率、計畫通、思維嚴謹、追求持續成長 #低層次捲髮增添溫暖氣質",
      info: "在你的領域，你的專業知識無人能敵，也非常善於制定計劃，如果事情沒有按照你制定的一磚一瓦走的話，你可能就會崩潰。你喜歡接觸新知，經常在獨處時思考這個世界各種學問，並嘗試讓自己不斷跳出舒適圈，『精益求精』是你在生活上最重要的信念！你是一個超級完美主義者，完美到連對別人的要求都跟對自己一樣苛刻，所以在外人眼中經常看起來冷漠無情也難以親近。魔髮師建議你嘗試平易近人的日系低層次捲度，為你有距離感的外表增加一些溫暖氛圍。",
      magic:
        "日系的低層次紋理，營造出像剛睡醒般的慵懶氣質，層層堆疊的捲度，最能展現日系男子的柔和氣場。此款捲髮若搭配慕斯系列的濕式造型品塗抹，會更有男人味喔！",
    },
    ISTP: {
      title: "線條感十足的日系浪人剪",
      tags: "#保守務實、內心充滿好奇、行動力強 #層次感剪裁凸顯你的自由灑脫",
      info: "你喜歡解決問題，遇到困難絕不會逃避，是個非常有行動力的人！低調謙虛的你，在他人眼中可能顯得冷淡寡言，時常透露出生人勿近的感覺，比起言語上的拐彎抹角，你更喜歡直接用行動表示，雖然不太懂浪漫，卻總是默默的關心他人。魔髮師建議你可以嘗試層次細緻、線條感十足的日系剪裁，在你嚴謹的個性上，添加一些柔和的效果，試著嘗試些新改變，才有機會看見自己不同的樣貌喔！",
      magic:
        "有層次感的剪裁設計打造出日系柔和線條，完美凸顯你精緻的五官，還能進一步修飾頭型，讓視覺上更顯頭小。建議再搭配上造型品營造出濕潤感，更能展現你的浪人氣質！",
    },
    ISFP: {
      title: "線條感十足的日系浪人剪",
      tags: "#溫柔文靜、談吐文雅、自由灑脫、滿滿藝術魂 #層次感剪裁凸顯你的自由灑脫",
      info: "看似自由灑脫不喜歡受拘束的你，實則性格細膩又敏銳。不認識你的人總是會被你的冰山外表給嚇跑不敢靠近你，殊不知呀，只要你的話匣子一打開，從天文地理聊到星座運勢你都停不下來....在一群朋友當中，你屬於默默的觀察派，重視整體關係的和諧，即便看破也不會說破，破壞氣氛最不符合你溫柔的氣質了！細膩敏感的你，最適合一頭層次豐富、線條細膩的日系剪裁，溫文儒雅中又帶點靈動的活潑，風輕輕吹過髮絲間營造出來的羽毛感，最能展現你自由不羈的意志。",
      magic:
        "有層次感的剪裁設計打造出日系柔和線條，完美凸顯你精緻的五官，還能進一步修飾頭型，讓視覺上更顯頭小。建議再搭配上造型品營造出濕潤感，更能展現你的浪人氣質！",
    },
    INFP: {
      title: "不規則捲紋叛逆狼尾頭",
      tags: "#反差、有理想、多愁善感、堅毅又冷漠 #不規則捲度最符合你的矛盾情感",
      info: "你自身就是一個矛盾體，外在冷靜害羞，內心其實充滿熱情，願意關懷他人，但也十分注重自己內心的和諧，經常需要透過獨處來消化情緒。平時主張和諧，非常有耐心和包容力，但當自己的價值觀受到質疑的時候，你會堅持自己的原則，非常固執！你喜愛探索、熱衷學習不同文化與新事物，但你對每件事情都過度講求完美的心態，會讓你整個人看起來太緊繃！外冷內熱的你最適合不規則的韓系捲度，彷彿就像在自己的被窩裡一樣舒服且無害，搭配上髮尾小小的狼尾營造出獨特的叛逆反差感。",
      magic:
        "韓系不規則的捲度，製造出髮量蓬鬆卻又不會過度厚重的輕盈感，加上狼尾的小細節，在視覺上延長了臉型的立體感以及頭型的飽滿度！",
    },
    INTP: {
      title: "不規則捲紋叛逆狼尾頭",
      tags: "#獨立、邏輯強、善於解決問題、喜歡沉思活在自己世界 #不規則捲度最符合你的矛盾情感",
      info: "你內向理性，喜歡待在自己的世界裡，不懂你的人可能覺得你難相處，總是看起來冷冰冰的。渴望獨立、喜愛思考，讓你成為別人眼中特別的存在。你對於感興趣的議題總是洋溢著熱情，也善於提出獨到的見解。你很注重理性的邏輯思考和溝通方式，譬如說你在吵架的時候，比起感性的爭一口輸贏，你更注重真實的對錯，並會認真的以邏輯去分析現況。這樣的你最建議你換上一顆韓系不規則的捲髮，有層次感的紋理搭配個性十足的小狼尾細節，更能凸顯你追求獨立、嚮往神秘的個人特質！",
      magic:
        "韓系不規則的捲度，製造出髮量蓬鬆卻又不會過度厚重的輕盈感，加上狼尾的小細節，在視覺上延長了臉型的立體感以及頭型的飽滿度！",
    },
    ESTP: {
      title: "充滿自信的明亮極短寸頭",
      tags: "#活潑有魅力、善於社交、享受生活 #明亮髮色展現你的自信灑脫",
      info: "想要在人群中快速找到你，跟著大家的目光看去準沒錯！你不只活躍在各種社交場合，也總是能輕易成為人群中的焦點，在與別人相處時，你言行舉止中流露出的自信與愜意，就是你最大的魅力之一！充滿創意、積極付諸行動、享受活在當下、擁有說做就做的勇氣，都是你被他人羨慕的特點。總是正能量爆棚、喜歡挑戰嘗鮮的你，魔髮師建議嘗試更多淺色系的髮色，讓你整體氛圍更加明亮、活潑，搶先走在流行的最前端。搭配上簡單俐落的寸頭設計，讓你風格大膽卻不落入俗套，反而散發更加幹練、使人信服的魅力與氣場。",
      magic:
        "透過淺髮色提亮你的門面形象，增添整體活力氛圍。極短寸頭設計最適合展現圓潤飽滿的頭型！",
    },
    ESFP: {
      title: "充滿自信的明亮極短寸頭",
      tags: "#真誠熱情、活潑好動、人群中最閃亮的一顆星 #明亮髮色展現你的自信灑脫",
      info: "只要有舞台、有燈光的地方，絕對少不了你的身影。你有強大的表演慾，擅長向他人展示自己最亮眼、最有自信的一面，不害怕舞台的你，總是非常勇敢地踏出舒適圈、擁抱新的挑戰。你非常樂天，很多事都會依靠直覺來解決，想到什麼就會直接付諸行動，旁人看來也許會覺得你過於直接魯莽，但其實你非常確定你的目標，而且勇往直前！滿腔熱血的你，極短寸頭加上極淺的髮色，最符合你表演欲強的性格，這麼時髦的髮型只有你能駕馭，盡情展現你熱情活潑的魅力吧！",
      magic:
        "透過淺髮色提亮你的門面形象，增添整體活力氛圍。極短寸頭設計最適合展現圓潤飽滿的頭型！",
    },
    ENFP: {
      title: "簡單舒適的韓系清爽短髮",
      tags: "#友善、有活力、夢想實踐家、好奇心滿滿 #微微的捲紋凸顯的你機智靈敏",
      info: "對你而言，天下沒有不可能的事，你相信團結的力量可以改變非常多的事情。活潑外向是你最常聽到的形容詞，你充滿好奇心、喜歡探索新鮮事物，因此難以忍受總是在做重複的事。你很重視與人之間的情感交流，待人真誠善良，同時內心也很希望能夠得到相等的認同和愛，這樣的你偶爾會受傷、變得脆弱。魔髮師建議你，在追求他人的愛和認同時，絕對要先愛自己，再去付出。熱情洋溢，活潑可愛的你，最適合韓系清爽的短髮線條剪裁，在慵懶的氛圍中，透過微捲的造型展現出你鬼靈機怪的俏皮和靈敏。",
      magic:
        "線條柔和的韓系剪裁，不僅修飾頭角問題，也讓整個人清爽了起來。搭配上微捲的造型感，看似不經意微亂的線條，清爽中又帶了點慵懶的氛圍。",
    },
    ENTP: {
      title: "簡單舒適的韓系清爽短髮",
      tags: "#機敏、直接、絕不被常規束縛、有邏輯善於分析 #微微的捲紋凸顯你的機智靈敏",
      info: "你生性聰明且熱愛自由、喜歡挑戰傳統常規，透過不斷的提問來探索生命並成長。你天性樂觀，即使遇到再大的挫折，都能勇敢解決。在他人眼裡，你雖然是非常容易親近的對象，但在相處過程中，你有時候會只顧著表達自己的看法，而忽視了他人的意見。魔髮師建議你換上一顆線條柔和的髮型來磨和個性上的稜角，藉由造型的轉換來讓你整個人慢下來，搭配上微捲的造型展現出你的靈活度。比起一頭熱的辯論自己的想法，偶爾也試著傾聽別人，說不定會有更多意想不到的收穫呢！",
      magic:
        "線條柔和的韓系剪裁，不僅修飾頭角問題，也讓整個人清爽了起來。搭配上微捲的造型感，看似不經意微亂的線條，清爽中又帶了點慵懶的氛圍。",
    },
    ESTJ: {
      title: "霸氣十足的韓系紳士頭",
      tags: "#外向、掌握權力、重視秩序、誠實果斷、堅守原則 #紳士感造型凸顯你的霸氣",
      info: "敢愛敢恨、做事相當果斷的你，天生領導者的霸氣，有時候可是讓人有覺得有點距離感而無法靠近呢！脾氣雖然有點點小火爆，但凡事注重原則跟秩序的你，都還是對事不對人的呢！尤其你特別注重跟別人約定好的日子，也會為那些重大日子付出非常多的心力，讓他人有備受重視的感覺，是你最溫柔的照顧。平時就氣場極強的你，魔髮師建議可以換上一顆韓系紳士頭，符合你能幹俐落的氣質，加上微捲逗號瀏海，讓你展現在霸氣中不失溫柔的氣質。",
      magic:
        "比起全部往上梳的油頭髮型，韓系紳士頭藉由從兩側往後延伸的線條，搭配逗號瀏海的造型巧思，能更加修飾臉型及五官輪廓，再搭配上壓貼燙則可以貼平頭角及鬢角，更顯頭小喔！",
    },
    ESFJ: {
      title: "霸氣十足的韓系紳士頭",
      tags: "#全身散發滿到要溢出來的熱情正能量 #紳士感造型凸顯你的霸氣",
      info: "你活潑熱情外向、喜歡交朋友，身上更是自帶滿滿正能量來感染著身旁的人，交友圈超廣的你，走到哪裡都能受到大家的歡迎。雖然你的一舉一動都能影響著他人，但卻從不曾令人感到不舒服、有壓迫感，對大家來說，你是一位全身散發著魅力的天生領導者！溫暖熱心、來者不拒的你，最適合換上韓系的紳士頭，展露你的紳士溫柔氣質外，不經意的霸氣外露，讓人心甘情願臣服於你。",
      magic:
        "比起全部往上梳的油頭髮型，韓系紳士頭藉由從兩側往後延伸的線條，搭配逗號瀏海的造型巧思，能更加修飾臉型及五官輪廓，再搭配上壓貼燙則可以貼平頭角及鬢角，更顯頭小喔！",
    },
    ENFJ: {
      title: "俐落有型的微翹短髮",
      tags: "#幹練、有活力、天生的領導者、見多識廣充滿遠見 #俐落短髮展現你的王者氣質",
      info: "你的一舉一動都充滿著自信讓人想要跟隨你，你期待帶給人們正向的愛和希望，並用你的感染力來改變社會和世界！你善於換位思考，設身處地感知他人的情緒，並且熱心地給予幫助。喜愛擁抱人群的你，最適合換上一顆俐落造型感十足的短髮，藉由抓出兩側微翹造型，搭配有層次感的短瀏海，展現出更有個性、更有立體感的短髮風格，同時也為你營造更加強大的氣場！",
      magic:
        "層次、造型感十足的俐落短髮，透過短瀏海剪裁設計修飾臉部，讓輪廓看起來更加柔和，再加上兩側抓翹的造型，讓五官在視覺上更加集中，凸顯立體度。",
    },
    ENTJ: {
      title: "俐落有型的微翹短髮",
      tags: "#幹練、有活力、天生的領導者、見多識廣充滿遠見 #俐落短髮展現你的王者氣質",
      info: "行動力高、做事有條理的你，總是充滿活力的迎向挑戰、順利完成各種高難度任務！你能專注處理自己份內的事，也擅長帶領大家一起完成任務，你身上總是自然地散發一股堅定的精神與魅力，『認真的男人最有魅力』，絕對就是在形容你投入於專業領域時那股渾然天成的氣質！魔髮師建議自帶強者氣場的你，可以嘗試俐落造型感十足的短髮，藉由抓出兩側微翹造型，搭配有層次感的短瀏海，展現出更有個性、更有立體感的短髮風格！",
      magic:
        "層次、造型感十足的俐落短髮，透過短瀏海剪裁設計修飾臉部，讓輪廓看起來更加柔和，再加上兩側抓翹的造型，讓五官在視覺上更加集中，凸顯立體度。",
    },
  },
};

const Result = ({ code, name, gender }) => {
  const content = RESULT[gender][code];
  const { title, tags, info, magic } = content;
  return (
    <div id="result">
      <div className="title">
        {/* <a
          className="main-btn"
          style={{
            position: "absolute",
            fontSize: "15px",
            fontWeight: "800",
            width: "120px",
            lineHeight: "30px",
            left: "10px",
            top: '16px',
            marginTop: '0px'
          }}
          href="https://www.superbcut.com.tw/2022lookbook/2022mbti/"
          target="_blank"
        >
          查看抽獎規則
        </a> */}
        <img src={Images.nav} alt="" />
      </div>
      <div className="content">
        <div className="header">
          <img
            src={require(`../images/${gender}/${code}/r.png`).default}
            alt="底圖"
          />
          <h2 className="serif">{title}</h2>
        </div>
        <div className="text">
          <div className="text-title serif">
            {code}
            <br />
            試試看這個髮型吧，{name}!
          </div>
          <div className="tags serif">
            {tags.split("#").map(
              (t) =>
                t && (
                  <div className="tag" key={t}>
                    #{t}
                  </div>
                )
            )}
          </div>
          <div className="info">{info}</div>
          <div className="ig serif">
            <h2>
              歡迎截圖你的MBTI屬性，分享到IG限動Tag我們呦！(@superbcut)
            </h2>
            <a
              href="https://www.instagram.com/ar/590702292229468"
              target="_blank"
              className="main-btn serif"
              rel="noreferrer"
            >
              試試 IG 濾鏡換髮型
            </a>
          </div>
          <div className="magic-block">
            <img
              className="magic"
              src={require(`../images/${gender}/${code}/d.png`).default}
              alt="detail"
            />
            <h2 className="serif">---魔髮師髮型細節指引---</h2>
            {magic}
            <a
              href={`https://line.me/R/oaMessage/@superbcut/?我的測驗結果是${code}${
                gender === "male" ? "男" : "女"
              }，請問有推薦的設計師嗎？`}
              target="_blank"
              style={{
                width: "170px",
              }}
              rel="noreferrer"
              className="main-btn serif"
            >
              馬上預約變髮
            </a>
          </div>
        </div>
        <div
          className="rs"
          style={{
            backgroundImage: `url(${Images.bg})`,
            backgroundPosition: "0 170%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% auto",
          }}
        >
          <h2 className="serif">這些髮型也適合你......</h2>
          <div className="rsimgs">
            <div className="rsimg">
              <img
                src={require(`../images/${gender}/${code}/r1.png`).default}
                alt="推薦一"
              />
            </div>
            <div className="rsimg">
              <img
                src={require(`../images/${gender}/${code}/r2.png`).default}
                alt="推薦二"
              />
            </div>
          </div>
          <a
            href="https://www.superbcut.com.tw/2022lookbook/2022mbti/"
            target="_blank"
            className="main-btn serif"
            rel="noreferrer"
            style={{
              width: "170px",
            }}
          >
            看更多髮型參考
          </a>
        </div>
      </div>
    </div>
  );
};

export default Result;
