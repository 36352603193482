import { useEffect, useState } from "react";
import Question from "./Question";
import Result from "./Result";
import Images from "./images";
import "./App.css";

const getMode = (array, key1, key2) => {
  const key1Count = array.filter((key) => key === key1).length;
  return key1Count >= 2 ? key1 : key2;
};

const genResult = (result) => {
  const result1 = getMode(result, "e", "i");
  const result2 = getMode(result, "n", "s");
  const result3 = getMode(result, "t", "f");
  const result4 = getMode(result, "j", "p");
  return result1 + result2 + result3 + result4;
};

function App() {
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isModalShow, showModal] = useState(false);
  const [result, setResult] = useState([]);
  const [gender, setGender] = useState("male");
  const [name, setName] = useState("");
  // Question Logic

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div
      id="main"
      style={{
        backgroundImage: `url(${Images.bg})`,
      }}
    >
      {loading && (
        <div id="loading">
          <div className="lds-circle">
            <div></div>
          </div>
          <span>Loading...</span>
        </div>
      )}
      <div
        id="home"
        style={{
          opacity: index === 0 ? 1 : 0,
          pointerEvents: index === 0 ? "visible" : "none",
          transition: "1s ease",
        }}
      >
        <img className="home-elem" src={Images.home1} alt="main element" />
        <div className="bottom">
          {/* <div className="title serif">好剪才髮廊</div> */}
          <img src={Images.logo} alt="logo" className="logo" />
          <div className="main-btn serif" onClick={() => showModal(true)}>
            GO!
          </div>
          <div className="main-tip">
            <span className="line"></span>
            <span className="serif">一起照鏡去</span>
            <span className="line"></span>
          </div>
        </div>
        {isModalShow && (
          <div id="modal">
            <div className="modal-main">
              <div
                className="modal-logo"
                style={{
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundImage: `url(${Images.modalTitle})`,
                }}
              >
                <img src={Images.logo} alt="logo" className="logo" />
              </div>
              <div className="modal-text serif">
                魔鏡阿魔鏡！
                <br />
                我到底適合什麼樣的髮型呢？
                <br />
                <br />
                一款為你量身打造的髮型設計，不只要看頭型、臉型、五官比例來設計，最重要的是你能真心喜歡換上這款新髮型的自己！
                <br />
                <br />
                一起來照照好剪才自戀魔髮鏡，換上能讓你變得更自戀、更好看的命定髮型吧！
              </div>
              <div
                className="modal-btn main-btn serif"
                onClick={() => {
                  setIndex(1);
                }}
              >
                我要變好看！
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        id="questions"
        style={{
          opacity: index > 0 ? 1 : 0,
          pointerEvents: index > 0 ? "visible" : "none",
          transition: "1s ease",
        }}
      >
        <div className="bg">
          <div className="main-bg">
            <img src={Images.mirror} alt="" />
            <img className="bg-logo" src={Images.logo} alt="" />
            <div className="progress">
              <div
                className="mp"
                style={{
                  maxWidth: "100%",
                  width: (index / 12) * 100 + "%",
                }}
              ></div>
            </div>
          </div>
        </div>
        <Question
          className={index === 1 ? "active" : ""}
          q="當你換了一頭超滿意的新髮型去參加同學會，你會期待別人？"
          a0="拜託快點大力稱讚我的新髮型，讓我成為整場最亮焦點"
          a1="我知道我的新髮型很好看，但還是想低調點，沒人發現就好了"
          setResult={(aIndex) => {
            setIndex(2);
            setResult([...result, ["e", "i"][aIndex]]);
          }}
        />
        <Question
          className={index === 2 ? "active" : ""}
          q="當你認定了一位想要一輩子長相廝守的伴侶，你的戀愛腦是？"
          a0="陷入幻想與他一起甜蜜到老的各種浪漫片段"
          a1="馬上開始計算兩人現在的資產該如何規劃、未來何時能買房、要不要有小孩....."
          setResult={(aIndex) => {
            setIndex(3);
            setResult([...result, ["n", "s"][aIndex]]);
          }}
        />
        <Question
          className={index === 3 ? "active" : ""}
          q="看到朋友換了一頭完全不適合他的髮型，你會怎麼做？"
          a0="我會很直接的跟他說這個髮型不適合他、哪裡有問題"
          a1="我還是會委婉的擠出一些讚美來誇獎他的新髮型"
          setResult={(aIndex) => {
            setIndex(4);
            setResult([...result, ["t", "f"][aIndex]]);
          }}
        />
        <Question
          className={index === 4 ? "active" : ""}
          q="又到了該上髮廊整理頭髮的時候了，你會選擇？"
          a0="總是剪一樣的髮型，讓我覺得最方便又安心"
          a1="每次都要嘗試不一樣的髮型，才不會看自己看到膩"
          setResult={(aIndex) => {
            setIndex(5);
            setResult([...result, ["j", "p"][aIndex]]);
          }}
        />
        <Question
          className={index === 5 ? "active" : ""}
          q="今天是禮拜五，終於結束疲憊工作一天，你會怎麼好好放鬆？"
          a0="小週末，當然要出去跟朋友狂歡釋放壓力啊"
          a1="出去玩更累，還是待在家裡泡澡放鬆就好"
          setResult={(aIndex) => {
            setIndex(6);
            setResult([...result, ["e", "i"][aIndex]]);
          }}
        />
        <Question
          className={index === 6 ? "active" : ""}
          q="在跟髮型師說明自己想要怎樣的髮型時，通常你會？"
          a0="我會用形容的方式去表達，像是：我要看起來俐落、有氣勢、看起來不好惹的那種髮型！"
          a1="我會很準確的說明細節，像是：我要中分八字瀏海、長度剪到鎖骨、再燙個內彎！"
          setResult={(aIndex) => {
            setIndex(7);
            setResult([...result, ["n", "s"][aIndex]]);
          }}
        />
        <Question
          className={index === 7 ? "active" : ""}
          q="在 IG 上滑到最近超夯的美食餐廳看起來超好吃，但剛好你正開始在進行減肥計畫 …"
          a0="在瘦下 5KG 之前我什麼都能忍，努力調整心態拒絕誘惑"
          a1="想吃就去，減肥期間還是希望自己有幾餐能吃得快樂一點"
          setResult={(aIndex) => {
            setIndex(8);
            setResult([...result, ["t", "f"][aIndex]]);
          }}
        />
        <Question
          className={index === 8 ? "active" : ""}
          q="在跟髮型師溝通髮型設計的方向時，通常你會？"
          a0="我很確定知道自己想要什麼髮型，髮型師只要照著我的想法做就好 "
          a1="我沒有什麼想法，一切都放心交給髮型師處理就好"
          setResult={(aIndex) => {
            setIndex(9);
            setResult([...result, ["j", "p"][aIndex]]);
          }}
        />
        <Question
          className={index === 9 ? "active" : ""}
          q="在燙頭髮的漫長 3 小時裡，你喜歡？"
          a0="跟髮型師從頭聊到尾，聊天最好打發時間了 "
          a1="放空、滑手機、看書，享受安靜沒人打擾的休息時光"
          setResult={(aIndex) => {
            setIndex(10);
            setResult([...result, ["e", "i"][aIndex]]);
          }}
        />
        <Question
          className={index === 10 ? "active" : ""}
          q="不管是準備要畢業、轉職、結婚、搬家...面臨各種人生新挑戰的時候，你都習慣怎麼做呢？"
          a0="只要有好機會來臨就勇往直前，相信跟著自己的直覺走，一切都會有好的發展！"
          a1="靜下心來理清自己的現況，並先四處搜集相關資訊，做好一切事前準備後迎接挑戰！"
          setResult={(aIndex) => {
            setIndex(11);
            setResult([...result, ["n", "s"][aIndex]]);
          }}
        />
        <Question
          className={index === 11 ? "active" : ""}
          q="看到自己欣賞的男神/女神燙了一顆超好看的新髮型，被燒到不行的你會？"
          a0="先認真分析自己的臉型、膚色、五官比例，思考能不能成功駕馭這個髮型 "
          a1="立馬預約好剪才，請髮型師幫你燙一顆一模一樣的髮型！"
          setResult={(aIndex) => {
            setIndex(12);
            setResult([...result, ["t", "f"][aIndex]]);
          }}
        />
        <Question
          className={index === 12 ? "active" : ""}
          q="過完這個月，就要準備迎接2022年了，你是哪一派？"
          a0="早就著手開始計劃2022的新目標與年計畫！"
          a1="反正計劃趕不上變化，把握每個當下、聽從自己的想法去過生活就好！"
          setResult={(aIndex) => {
            setIndex(13);
            setResult([...result, ["j", "p"][aIndex]]);
          }}
        />
      </div>
      {index === 13 && (
        <div id="result-modal">
          <div className="modal-main">
            <div className="label">
              <span className="line"></span>
              <span className="serif">姓名</span>
              <span className="line"></span>
            </div>
            <div className="input">
              <input type="text" onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="label">
              <span className="line"></span>
              <span className="serif">性別</span>
              <span className="line"></span>
            </div>
            <div className="input">
              <select onChange={(e) => setGender(e.target.value)}>
                <option value="male">男</option>
                <option value="female">女</option>
              </select>
            </div>
            <div
              className="modal-btn main-btn serif"
              onClick={() => {
                if (!name) {
                  window.alert("請輸入姓名");
                } else {
                  setIndex(14);
                }
              }}
            >
              ENTER！
            </div>
          </div>
        </div>
      )}
      {index === 14 && (
        <Result
          code={genResult(result).toUpperCase()}
          name={name}
          gender={gender}
          setLoading={setLoading}
        />
      )}
    </div>
  );
}

export default App;
