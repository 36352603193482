import "./index.css";

const Question = ({ q, a0, a1, setResult, className }) => {
  return (
    <div className={`question ${className}`}>
      <div className="q serif">
        <div className="title serif">Q <span>:</span></div>
        {q}
      </div>
      <div className="a-btn" onClick={() => setResult(0)}>
        {a0}
      </div>
      <div className="a-btn" onClick={() => setResult(1)}>
        {a1}
      </div>
    </div>
  );
};

export default Question;
