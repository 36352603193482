import bg from "./bg.jpg";
import home1 from "./home1.png";
import logo from "./logo.png";
import mirror from "./mirror.png";
import modalTitle from './modaltitle.png';
import nav from './result-header.svg';

const Images = {
  bg,
  home1,
  logo,
  mirror,
  modalTitle,
  nav
};

export default Images;
